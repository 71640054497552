
import VuexORM, { Model } from '@vuex-orm/core'

const database = new VuexORM.Database()
export { database }

export class CustomizerItem extends Model {
  static entity = 'customizersItems'

  static primaryKey = ['itemId', 'customizerId']

  static fields () {
    return {
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      rank: this.number(null).nullable(),
      itemId: this.uid(null).nullable(),
      customizerId: this.uid(null).nullable(),
      price: this.number(null).nullable(),
      limit: this.number(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      customizer: this.belongsTo(Customizer, 'customizerId'),
      item: this.belongsTo(Item, 'itemId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/customizers_items`
  }

  static afterDelete (model) {

  }
}

database.register(CustomizerItem)

export class Tab extends Model {
  static entity = 'tabs'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      isResolved: this.boolean(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      tabUsers: this.hasMany(TabUser, 'tabId'),
      tabItems: this.hasMany(TabItem, 'tabId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/tabs`
  }

  static afterDelete (model) {

  }
}

database.register(Tab)

export class Item extends Model {
  static entity = 'items'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      description: this.string(null).nullable(),
      price: this.number(null).nullable(),
      vat: this.number(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      itemCustomizers: this.hasMany(ItemCustomizer, 'itemId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/items`
  }

  static afterDelete (model) {
    ItemCustomizer.delete(e => e.itemId === model[this.primaryKey])
  }
}

database.register(Item)

export class TabUser extends Model {
  static entity = 'tabsUsers'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      tabId: this.uid(null).nullable(),
      userId: this.uid(null).nullable(),
      tab: this.belongsTo(Tab, 'tabId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/tabs_users`
  }

  static afterDelete (model) {

  }
}

database.register(TabUser)

export class CategoryItem extends Model {
  static entity = 'categoriesItems'

  static primaryKey = ['categoryId', 'itemId']

  static fields () {
    return {
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      rank: this.number(null).nullable(),
      categoryId: this.uid(null).nullable(),
      itemId: this.uid(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      category: this.belongsTo(Category, 'categoryId'),
      item: this.belongsTo(Item, 'itemId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/categories_items`
  }

  static afterDelete (model) {

  }
}

database.register(CategoryItem)

export class Company extends Model {
  static entity = 'companies'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      legalId: this.string(null).nullable(),
      establishments: this.hasMany(Establishment, 'companyId'),
      companyUsers: this.hasMany(CompanyUser, 'companyId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/companies`
  }

  static afterDelete (model) {
    Establishment.delete(e => e.companyId === model[this.primaryKey])
    CompanyUser.delete(e => e.companyId === model[this.primaryKey])
  }
}

database.register(Company)

export class Customizer extends Model {
  static entity = 'customizers'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      limit: this.number(null).nullable(),
      required: this.boolean(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      customizerItems: this.hasMany(CustomizerItem, 'customizerId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/customizers`
  }

  static afterDelete (model) {
    CustomizerItem.delete(e => e.customizerId === model[this.primaryKey])
  }
}

database.register(Customizer)

export class MenuCategory extends Model {
  static entity = 'menusCategories'

  static primaryKey = ['menuId', 'categoryId']

  static fields () {
    return {
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      rank: this.number(null).nullable(),
      menuId: this.uid(null).nullable(),
      categoryId: this.uid(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      menu: this.belongsTo(Menu, 'menuId'),
      category: this.belongsTo(Category, 'categoryId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/menus_categories`
  }

  static afterDelete (model) {

  }
}

database.register(MenuCategory)

export class ItemCustomizer extends Model {
  static entity = 'itemsCustomizers'

  static primaryKey = ['itemId', 'customizerId']

  static fields () {
    return {
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      rank: this.number(null).nullable(),
      itemId: this.uid(null).nullable(),
      customizerId: this.uid(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      item: this.belongsTo(Item, 'itemId'),
      customizer: this.belongsTo(Customizer, 'customizerId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/items_customizers`
  }

  static afterDelete (model) {

  }
}

database.register(ItemCustomizer)

export class Table extends Model {
  static entity = 'tables'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      qr: this.string(null).nullable(),
      number: this.number(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      tabItems: this.hasMany(TabItem, 'tableId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/tables`
  }

  static afterDelete (model) {

  }
}

database.register(Table)

export class CompanyUser extends Model {
  static entity = 'companiesUsers'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      userId: this.uid(null).nullable(),
      isAdmin: this.boolean(null).nullable(),
      companyId: this.uid(null).nullable(),
      company: this.belongsTo(Company, 'companyId'),
      user: this.belongsTo(User, 'userId'),
      establishmentUsers: this.hasMany(EstablishmentUser, 'companyUserId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/companies_users`
  }

  static afterDelete (model) {
    EstablishmentUser.delete(e => e.companyUserId === model[this.primaryKey])
  }
}

database.register(CompanyUser)

export class EstablishmentUser extends Model {
  static entity = 'establishmentsUsers'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      companyUserId: this.uid(null).nullable(),
      userId: this.uid(null).nullable(),
      role: this.string(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      user: this.belongsTo(User, 'userId'),
      companyUser: this.belongsTo(CompanyUser, 'companyUserId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/establishments_users`
  }

  static afterDelete (model) {

  }
}

database.register(EstablishmentUser)

export class User extends Model {
  static entity = 'users'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      firstName: this.string(null).nullable(),
      lastName: this.string(null).nullable(),
      cellphoneNumber: this.string(null).nullable(),
      cellphoneCc: this.string(null).nullable(),
      cellphoneValidationDate: this.attr(null).nullable(),
      email: this.string(null).nullable(),
      emailValidationDate: this.attr(null).nullable(),
      password: this.string(null).nullable(),
      fbId: this.string(null).nullable(),
      fbAccessToken: this.string(null).nullable(),
      googleId: this.string(null).nullable(),
      googleAccessToken: this.string(null).nullable(),
      companyUsers: this.hasMany(CompanyUser, 'userId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/users`
  }

  static afterDelete (model) {

  }
}

database.register(User)

export class TabItem extends Model {
  static entity = 'tabItems'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      tabId: this.uid(null).nullable(),
      tableId: this.uid(null).nullable(),
      itemId: this.uid(null).nullable(),
      userId: this.uid(null).nullable(),
      quantity: this.number(null).nullable(),
      status: this.string(null).nullable(),
      tab: this.belongsTo(Tab, 'tabId'),
      item: this.belongsTo(Item, 'itemId'),
      table: this.belongsTo(Table, 'tableId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/tab_items`
  }

  static afterDelete (model) {

  }
}

database.register(TabItem)

export class Category extends Model {
  static entity = 'categories'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      categoryItems: this.hasMany(CategoryItem, 'categoryId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/categories`
  }

  static afterDelete (model) {
    CategoryItem.delete(e => e.categoryId === model[this.primaryKey])
  }
}

database.register(Category)

export class Establishment extends Model {
  static entity = 'establishments'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      legalId: this.string(null).nullable(),
      street: this.string(null).nullable(),
      city: this.string(null).nullable(),
      zipCode: this.string(null).nullable(),
      country: this.string(null).nullable(),
      companyId: this.uid(null).nullable(),
      company: this.belongsTo(Company, 'companyId'),
      menus: this.hasMany(Menu, 'establishmentId'),
      categories: this.hasMany(Category, 'establishmentId'),
      items: this.hasMany(Item, 'establishmentId'),
      customizers: this.hasMany(Customizer, 'establishmentId'),
      tables: this.hasMany(Table, 'establishmentId'),
      tabs: this.hasMany(Tab, 'establishmentId'),
      establishmentUsers: this.hasMany(EstablishmentUser, 'establishmentId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/establishments`
  }

  static afterDelete (model) {
    EstablishmentUser.delete(e => e.establishmentId === model[this.primaryKey])
  }
}

database.register(Establishment)

export class Menu extends Model {
  static entity = 'menus'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.uid(null).nullable(),
      createdAt: this.attr(null).nullable(),
      updatedAt: this.attr(null).nullable(),
      name: this.string(null).nullable(),
      isActive: this.boolean(null).nullable(),
      establishmentId: this.uid(null).nullable(),
      companyId: this.uid(null).nullable(),
      establishment: this.belongsTo(Establishment, 'establishmentId'),
      menuCategories: this.hasMany(MenuCategory, 'menuId')
    }
  }

  static apiConfig = {
    baseURL: `${process.env.VUE_APP_API}/api/menus`
  }

  static afterDelete (model) {
    MenuCategory.delete(e => e.menuId === model[this.primaryKey])
  }
}

database.register(Menu)
