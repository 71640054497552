import Axios from 'axios'
import store from '@/store'
import router from '@/router'

const retryClient = Axios.create({
  headers: {
    'Content-type': 'application/json'
  },
  baseURL: process.env.VUE_APP_API,
  xsrfCookieName: 'csrf_access_token',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  withCredentials: true
})

const authApiClient = Axios.create({
  headers: {
    'Content-type': 'application/json'
  },
  baseURL: process.env.VUE_APP_AUTH_API,
  xsrfCookieName: 'csrf_refresh_token',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  withCredentials: true
})

authApiClient.interceptors.response.use(
  response => response,
  async error => {
    const { config } = error
    if (config.url.startsWith('/api')) {
      await store.dispatch('auth/refresh')
      return retryClient(config)
    } else {
      return error
    }
  }
)

export const authService = {
  signup (payload) {
    return authApiClient.post('/signup', payload)
  },
  login (email, password) {
    return authApiClient.post('/login', { email, password })
  },
  logout () {
    return authApiClient.delete('/token')
  },
  refresh (data) {
    return authApiClient.post('/token', data, { xsrfCookieName: 'csrf_refresh_token' })
  },
  getMe: () => authApiClient.get('/api/me')

}

export const apiClient = Axios.create({
  headers: {
    'Content-type': 'application/json'
  },
  baseURL: process.env.VUE_APP_API,
  xsrfCookieName: 'csrf_access_token',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  withCredentials: true
})

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = await error
    if (config.baseURL.startsWith('/api')) {
      if (response.status === 401) {
        await store.dispatch('auth/refresh')
        return retryClient(config)
      } else if (response.status === 403) {
        await router.push({ name: 'App-Companies' })
      } else {
        return error
      }
    }
  }
)

export const actionService = {
  searchUser: async (params) => apiClient.get('/api/users/search', { params: params })
}
