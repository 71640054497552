import { authService } from '@/services'
import {
  Category,
  CategoryItem,
  Company,
  Customizer, CustomizerItem,
  Establishment,
  Item,
  ItemCustomizer,
  Menu,
  MenuCategory
} from '@/store/models'

export default {
  namespaced: true,
  state: {
    me: undefined,
    activeEstablishmentId: null
  },
  getters: {
    activeCompany: (state) => Company.query().find(state.me.scopes.company),
    activeEstablishment: (state) => Establishment.query().find(state.activeEstablishmentId),
    defaultNew: (state) => { return { companyId: state.me.scopes.company, establishmentId: state.activeEstablishmentId } },
    menus: (state) => Menu.query()
      .with('menuCategories.category')
      .where('establishmentId', state.activeEstablishmentId),
    menuCategories: (state) => MenuCategory.query()
      .where('establishmentId', state.activeEstablishmentId).orderBy('rank').all(),
    categories: (state) => Category.query()
      .with('categoryItems.item')
      .where('establishmentId', state.activeEstablishmentId),
    categoryItems: (state) => CategoryItem.query()
      .where('establishmentId', state.activeEstablishmentId).orderBy('rank').all(),
    items: (state) => Item.query()
      .with('itemCustomizers.customizer')
      .where('establishmentId', state.activeEstablishmentId),
    itemCustomizers: (state) => ItemCustomizer.query()
      .where('establishmentId', state.activeEstablishmentId).orderBy('rank').all(),
    customizers: (state) => Customizer.query()
      .with('customizerItems.item')
      .where('establishmentId', state.activeEstablishmentId),
    customizerItems: (state) => CustomizerItem.query()
      .where('establishmentId', state.activeEstablishmentId).orderBy('rank').all()
  },
  mutations: {
    setMe (state, data) {
      state.me = data
    },
    setActiveEstablishmentId (state, value) {
      state.activeEstablishmentId = value
    }
  },
  actions: {
    async getMe ({ commit }) {
      try {
        const response = await authService.getMe()
        commit('setMe', response.data)
        return response
      } catch (e) {
        console.log(e)
      }
    }
  }
}
