import { authService } from '@/services'
import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {
  },
  actions: {
    async signup ({ dispatch }, payload) {
      const { status } = await authService.signup(payload)
      if (status === 201) {
        dispatch('login', { email: payload.email, password: payload.password })
      }
    },
    async login (context, { email, password }) {
      const { status } = await authService.login(email, password)
      if (status === 200) {
        await router.push({ name: 'App' })
      }
    },
    async logout ({ dispatch }) {
      function getCookie (name) {
        return document.cookie.split(';').some(c => {
          return c.trim().startsWith(name + '=')
        })
      }
      function deleteCookie (name, path, domain) {
        if (getCookie(name)) {
          document.cookie = name + '=' +
            ((path) ? ';Path=' + path : '') +
            ((domain) ? ';domain=' + domain : '') +
            ';'
        }
      }
      try {
        await authService.logout()
      } catch (e) {
        console.log(e)
      }
      deleteCookie('csrf_access_token', '/', location.hostname)
      deleteCookie('csrf_refresh_token', '/', location.hostname)
      await dispatch('entities/deleteAll')
      window.location.href = '/account'
    },
    async refresh (_, data) {
      try {
        return await authService.refresh(data)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
