<template>
  <transition>
    <router-view />
  </transition>
</template>

<script>

export default {
}
</script>
