import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth.module'
import app from '@/store/app.module'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import { apiClient } from '@/services'
import { database } from '@/store/models'

Vue.use(Vuex)

VuexORM.use(VuexORMAxios, { axios: apiClient })

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    app
  },
  plugins: [VuexORM.install(database)]
})
